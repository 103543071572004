import React from 'react';

class DepthLayer extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount () {
  }

  render () {
    let depth = this.props.depth;

    return (
      <>
        {this.props.depthNumber == 'info' ? 
          <section 
            className={`depth-layer depth-layer--info`}
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              transform: depth.depthInfo,
            }}
          >
            {this.props.children}
          </section>
        :
          <section 
            className={`depth-layer depth-layer--${this.props.depthNumber}`}
            style={{
              position: 'absolute',
              width: '100vw',
              height: '100vh',
              transform: depth[`depth${this.props.depthNumber}`],
            }}
          >
            {this.props.children}
          </section>
        }
      </>
    )
  }
}

export default DepthLayer;