import React from 'react';
import { connect } from 'react-redux';
import Img from "gatsby-image"
import Layout from '../components/layout';
import SEO from '../components/seo';
import DepthLayer from '../components/parallax/DepthLayer';
import { updateCursor, updateWindow } from '../state/state-movement';
import { graphql } from 'gatsby';
import About from '../svg/about-icon.svg'
import Events from '../svg/events-icon.svg'
import Gallery from '../svg/gallery-icon.svg'
import Services from '../svg/services-icon.svg'
import Contact from '../svg/contact-icon.svg'
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Arrow from '../svg/left-arrow.svg'

class IndexPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      menuActive: false
    };
  }

  componentDidMount() {
    this.images = document.querySelectorAll(".home .parallax-image").length;
    this.imagesLoaded = 0;
    setTimeout(() => {
      this.setState({menuActive: true});
    }, 2000);
  }


  imageLoaded = () => {
    this.imagesLoaded++;
    if (this.images == this.imagesLoaded) {
      this.afterImagesLoad(500);
    }
  }

  afterImagesLoad = (delay) => {
    setTimeout(() => {
      console.log('images loaded');
    }, delay);
  }

  render () {
    let data = this.props.data;
    let depth = this.props.depth;
    let movement = this.props.movement;

    return (
      <>
        <SEO title="Home" bodyType="above" description="Telekinetik Chair Repair is a Melbourne based art collective and Burning Seed theme camp, specialising in photoluminescent art installations and laser projections."/>
        <Layout 
          transitionStatus={this.props.transitionStatus}
          transitionDirection={this.props.current.state.direction}
          class={`home`}
        >
          {/* background */}
          <DepthLayer depth={depth} depthNumber={1}>
            <Img 
              className="parallax-image home-image__dust"
              fluid={data.dust.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
            />
          </DepthLayer>
          <DepthLayer depth={depth} depthNumber={2}>
            <Img 
              className="parallax-image home-image__shapes"
              fluid={data.shapes.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
              style={{
                transform: `translate(-50%, -50%) rotate(${movement.distanceFromMiddleX * .005}deg)`,
              }}
            />
          </DepthLayer>
          <DepthLayer depth={depth} depthNumber={3}>
            <Img 
              className="parallax-image home-image__circuits"
              fluid={data.circuits.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
            />
          </DepthLayer>
          {/* midground */}
          <DepthLayer depth={depth} depthNumber={4}>
            <Img 
              className="parallax-image home-image__shapes1"
              fluid={data.shapes1.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
              style={{
                transform: `translate(-50%, -50%) rotate(${movement.distanceFromMiddleX * .005}deg)`,
              }}
            />
            <Img 
              className="parallax-image home-image__spinny"
              fluid={data.spinny.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
              style={{
                transform: `translate(-50%, -50%) rotate(${movement.distanceFromMiddleX * .1}deg)`,
              }}
            />
            <Img 
              className="parallax-image home-image__eye"
              fluid={data.eye.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
            />
          </DepthLayer>
          {/* foreground */}
          <DepthLayer depth={depth} depthNumber={5}>
            <Img 
              className="parallax-image home-image__telekinetik"
              fluid={data.telekinetik.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
            />
          </DepthLayer>
          <DepthLayer depth={depth} depthNumber={5}>
            <Img 
              className="parallax-image home-image__shapes2"
              fluid={data.shapes2.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
              style={{
                transform: `translate(-50%, -50%) rotate(${movement.distanceFromMiddleX * -.045}deg)`,
              }}
            />
            <Img 
              className="parallax-image home-image__shapes3"
              fluid={data.shapes3.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
              style={{
                transform: `translate(-50%, -50%) rotate(${movement.distanceFromMiddleX * -.02}deg)`,
              }}
            />
            <Img 
              className="parallax-image home-image__shapes5"
              fluid={data.shapes5.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
              style={{
                transform: `translate(-50%, -50%) rotate(${movement.distanceFromMiddleX * .005}deg)`,
              }}
            />
            <Img 
              className="parallax-image home-image__shapes4"
              fluid={data.shapes4.childImageSharp.fluid}
              onLoad={this.imageLoaded}
              loading={'eager'}
              style={{
                transform: `translate(-50%, -50%) rotate(${movement.distanceFromMiddleX * .025}deg)`,
              }}
            />
          </DepthLayer>
          <DepthLayer depth={depth} depthNumber={6}>
            <div className={`home-nav ${this.state.menuActive && 'active'}`}>

              <AniLink to={`/about`} fade duration={2} className={`home-nav__link home-nav__link--about`}>
                <div className="home-nav__icon">
                  <About/>
                </div>
                <span className="home-nav__text">About</span>
              </AniLink>

              <AniLink to={`/events`} fade duration={2} className={`home-nav__link home-nav__link--events`}>
                <div className="home-nav__icon">
                  <Events/>
                </div>
                <span className="home-nav__text">Events</span>
              </AniLink>

              <AniLink to={`/gallery`} fade duration={2} className={`home-nav__link home-nav__link--gallery`}>
                <div className="home-nav__icon">
                  <Gallery/>
                </div>
                <span className="home-nav__text">Gallery</span>
              </AniLink>

              <AniLink to={`/collaboration`} fade duration={2} className={`home-nav__link home-nav__link--services`}>
                <div className="home-nav__icon">
                  <Services/>
                </div>
                <span className="home-nav__text">Collab</span>
              </AniLink>

              <AniLink to={`/contact`} fade duration={2} className={`home-nav__link home-nav__link--contact`}>
                <div className="home-nav__icon">
                  <Contact/>
                </div>
                <span className="home-nav__text">Contact</span>
              </AniLink>
            </div>

          </DepthLayer>
          <AniLink to={`/about`} fade duration={1} className={`home-about-link ${this.state.menuActive ? 'active' : ''}`}>
            <span className="content-link__text">Follow the eye</span>
            <Arrow/>
          </AniLink>
        </Layout>
      </>
    )
  }
}

export const query = graphql`
  query {
    telekinetik: file(relativePath: { eq: "telekinetik.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    spinny: file(relativePath: { eq: "spinny.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    eye: file(relativePath: { eq: "eye.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    circuits: file(relativePath: { eq: "circuits.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    dust: file(relativePath: { eq: "dust.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shapes: file(relativePath: { eq: "shapes.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shapes1: file(relativePath: { eq: "shapes1.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shapes2: file(relativePath: { eq: "shapes2.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shapes3: file(relativePath: { eq: "shapes3.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shapes4: file(relativePath: { eq: "shapes4.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    shapes5: file(relativePath: { eq: "shapes5.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default connect(
  state => ({ 
    window: state.movementState.windowDimensions,
    depth: state.movementState.depthLevels,
    movement: state.movementState,
  }),
  dispatch => ({ 
    updateCursor: cursorObj => dispatch(updateCursor(cursorObj)),
    updateWindow: windowObj => dispatch(updateWindow(windowObj)),
  }),
)(IndexPage);